/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import o from "../Extent.js";
import r from "../Geometry.js";
import n from "../Multipoint.js";
import e from "../Point.js";
import i from "../Polygon.js";
import t from "../Polyline.js";
function m(o) {
  return void 0 !== o.xmin && void 0 !== o.ymin && void 0 !== o.xmax && void 0 !== o.ymax;
}
function l(o) {
  return void 0 !== o.points;
}
function u(o) {
  return void 0 !== o.x && void 0 !== o.y;
}
function s(o) {
  return void 0 !== o.paths;
}
function f(o) {
  return void 0 !== o.rings;
}
function y(y) {
  return null == y ? null : y instanceof r ? y : u(y) ? e.fromJSON(y) : s(y) ? t.fromJSON(y) : f(y) ? i.fromJSON(y) : l(y) ? n.fromJSON(y) : m(y) ? o.fromJSON(y) : null;
}
function p(o) {
  return o ? u(o) ? "esriGeometryPoint" : s(o) ? "esriGeometryPolyline" : f(o) ? "esriGeometryPolygon" : m(o) ? "esriGeometryEnvelope" : l(o) ? "esriGeometryMultipoint" : null : null;
}
const v = {
  esriGeometryPoint: e,
  esriGeometryPolyline: t,
  esriGeometryPolygon: i,
  esriGeometryEnvelope: o,
  esriGeometryMultipoint: n
};
function G(o) {
  return o && v[o] || null;
}
export { y as fromJSON, G as getGeometryType, p as getJsonType, m as isExtent, l as isMultipoint, u as isPoint, f as isPolygon, s as isPolyline };